.CanvasHeader {
  margin: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
}

.CanvasHeaderTitle {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

.CanvasHeaderClose {
  font-size: 2rem;
  cursor: pointer;
}

.CanvasBody {
  height: calc(100dvh - 55px);
  padding: 0.5rem 1rem;
  overflow-y: auto;
}
