.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.AppDropdownIcon {
    border: none;
    font-size: 25px;
    height: 45px;
    width: 45px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
    color: gray;
}

.AppDropdownIcon:hover,
.AppDropdownIcon:active {
    background-color: rgb(234, 234, 234);
}

/* .EZDrawer__container {
  overflow: auto;
} */

.notifications {
    z-index: 1999 !important;
}

.helm-btn {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    padding: 0;
    transform: rotate(0deg) scale(1);
    transition: transform 0.3s ease-in-out;
}

.helm-btn:hover {
    background-color: rgb(214, 214, 214);
    transform: rotate(45deg) scale(1.1);
}

.helm-icon {
    font-size: 30px;
    margin: 0;
    padding: 0;
}
